@import '../../styles/variables';

.section_advantages {
    background-color: transparent;
    background-image: url('../../images/bg__advantages.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 100px 0;
    text-align: start;

    .count {
        font-size: $count-fonts-size;
        margin-bottom: 20px;
    }

    .description {
        font-size: $xl-font-size;
        margin-bottom: 20px;
    }

    .more {
        font-size: $lg-font-size;
        text-align: justify;
    }
}