@import '../../styles/variables';

.title__area {
    background-color: transparent;
    color: #fff;
    position: relative;
    overflow: hidden;

    &::before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../../images/bg__title.jpg');
        background-size: cover;
        background-position: center;
        z-index: -1;
    }

    .title_section__area {
        padding-top: 400px;

        .title_section__title {
            text-align: end;
            padding: 30px 30px 30px 0;
            font-size: $title-font-size;

            .description {
                font-size: $xl-font-size;
                font-weight: 300;
            }
        }
    }

    .section_oil {
        background-color: transparent;
        background-image: url('../../images/bg__section_2.webp');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .section_oil__area {
            .title_oil__content {
                text-align: start;
                height: 100%;
                display: flex;
                align-items: center;

                .title_oil__name {
                    text-align: start;
                    font-size: $xl-font-size;
                    margin-bottom: 20px;
                }

                .title_oil__description {
                    font-size: $lg-font-size;
                }

            }

            .title_oil__logo {
                height: 100%;
                display: flex;
                align-items: end;
                justify-content: end;

                img {
                    height: 50px;
                }
            }

        }
    }


    .section_katana {
        background: linear-gradient(90deg, rgba(213, 168, 81, 1) 0%, rgba(57, 47, 47, 1) 50%, rgba(213, 168, 81, 1) 100%);
        padding: 100px 100px 100px 0;

        .title {
            font-size: $title-font-size;
            text-align: start;
        }

        .description {
            font-size: $xl-font-size;
            text-align: justify;
            border-left: 3px solid #f1d476;
            padding-left: 10px;


        }
    }

    .section_advantages {
        background-color: transparent;
        background-image: url('../../images/bg__advantages.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding: 100px 0;
        text-align: start;

        .count {
            font-size: $count-fonts-size;
            margin-bottom: 20px;
        }

        .description {
            font-size: $xl-font-size;
            margin-bottom: 20px;
        }

        .more {
            font-size: $lg-font-size;
            text-align: justify;
        }
    }


}

