@import '../../styles/variables';

// .section_products {
//     background-color: rgba(213, 168, 81, 1);
//     text-align: start;
//     padding: 100px 0;

//     .all_products {
//         font-size: $lg-font-size;
//         margin-bottom: 10px;
//     }

//     .products__grid {
//         display: grid;
//         grid-template-rows: 1fr;
//         grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
//         gap: 30px;
//         height: 100%;

//         .product {
//             background-color: rgba(57, 47, 47, .7);
//             text-align: center;

//             .title {
//                 font-size: $md-font-size;
//                 padding: 0 10px;
//             }
//         }

//         .product {
//             text-align: center;
//             padding: 10px;

//             .product__image {
//                 max-width: 100%;
//                 height: auto;
//                 border-radius: 8px;
//                 margin-bottom: 10px;
//             }

//             .product__title {
//                 font-size: 16px;
//                 font-weight: bold;
//             }
//         }

//         .ant-carousel {
//             .slick-dots {
//                 bottom: -30px;
//             }

//             .slick-prev,
//             .slick-next {
//                 z-index: 10;
//             }
//         }
//     }
// }

.section_products {
    background-color: rgba(213, 168, 81, 1);
    text-align: start;
    padding: 100px 0;

    .title {
        text-align: center;
        margin-bottom: 20px;
    }

    .all_products {
        font-size: 18px;
        text-align: center;
        margin-bottom: 20px;
    }

    .product {
        text-align: center;
        padding: 10px;
        margin-bottom: 30px;

        .product__image {
            max-width: 100%;
            height: auto;
            border-radius: 8px;
            margin-bottom: 10px;
        }

        .product__title {
            font-size: $lg-font-size;
            font-weight: bold;
        }
    }

    .swiper {
        padding: 20px 0;
    }
    .swiper-pagination-bullet {
    
        background-color: #000;
        opacity: 0.7;

        &.swiper-pagination-bullet-active {
            background-color: #fff;
            opacity: 1;
        }
    }
}