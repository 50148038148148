@import '../../styles/variables.scss';

.title_katana__area {
    font-size: $title-font-size;
    font-weight: 500;
    padding: 20px 0;
    position: relative;
    color: #fff;
    background-color: transparent;
}
