@import '../../styles/variables';

.section_about {
    background-color: #000;
    padding-top: 100px;
    text-align: start;
    position: relative;

    .about__logo {
        img {
            height: 90px;
            position: relative;
            z-index: 1;

            @media (max-width: 768px) {
                height: 50px;
            }
        }
    }

    .about__content {
        padding: 60px 0;
        position: relative;
        z-index: 1;

        .title {
            font-size: $xxl-font-size;
            border-left: 3px solid #f1d476;
            padding-left: 10px;
        }

        .description {
            padding-top: 20px;
            border-left: 3px solid #f1d476;
            font-size: $xl-font-size;
            padding-left: 10px;
        }

        .more {
            text-align: justify;
            border-left: 1px solid #79693d;
            padding-top: 40px;
            padding-left: 13px;
            font-size: $lg-font-size;
        }
    }

    .bg__oil {
        position: absolute;
        z-index: 0;
        top: 40px;

        img {
            opacity: .6;
            height: 500px;
        }
    }
}