@import '../../styles/variables';

.section_technologies {
    background-color: transparent;
    background-image: url('../../images/bg__technologies.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    padding: 100px 0;

    position:relative;

    .description {
        font-size: $xxl-font-size;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .content {
        text-align: start;

        .title_technologies__area {
            display: flex;
            align-items: center;
            height: 100%;
            margin-bottom: 15px;

            .icon {
                height: 100%;
                width: 50px;
                margin-right: 10px;
            }

            .title {
                font-size: $xl-font-size;
            }
        }

        .description {
            font-size: $lg-font-size;
            font-weight: 300;
            text-align: justify;
        }
    }

    .content:not(:last-child) {
        margin-bottom: 20px;
    }

    .oil {
        img {
            height: 700px;
        }
    }
}