@import '../../styles/variables';

.section_katana {
    background: linear-gradient(90deg, rgba(213, 168, 81, 1) 0%, rgba(57, 47, 47, 1) 50%, rgba(213, 168, 81, 1) 100%);
    padding: 100px 100px 100px 0;

    .title {
        font-size: $title-font-size;
        text-align: start;
    }

    .description {
        font-size: $xl-font-size;
        text-align: justify;
        border-left: 3px solid #f1d476;
        padding-left: 10px;


    }
}