@import './styles/_fonts.scss';

html {
    scroll-behavior: smooth;

}
body {
    margin: 0;
    font-family: 'Circe', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ant-typography,
.ant-btn,
.ant-input,
.ant-select,
.ant-checkbox,
.ant-radio,
.ant-switch,
.ant-slider,
.ant-progress,
.ant-card,
.ant-table,
.ant-pagination,
.ant-modal,
.ant-drawer,
.ant-alert,
.ant-badge,
.ant-tooltip,
.ant-popover,
.ant-dropdown,
.ant-tree,
.ant-collapse,
.ant-tabs,
.ant-list,
.ant-avatar,
.ant-breadcrumb,
.ant-carousel,
.ant-progress,
.ant-rate,
.ant-descriptions,
.ant-menu,
.ant-layout,
.ant-col,
.ant-timeline {
    font-family: 'Circe';
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
}
