@import '../../styles/variables';

.katana__menu {
    padding-top: 40px;
    width: 100%;

    .header__logo-menu {
        display: flex;
        align-items: center;
    }

    .header__logo {
        height: 50px;
        margin-right: 16px;
    }

    .header__nav {
        background-color: transparent;
        width: 100%;
        text-align: end;
        font-size: $md-font-size;
        display: block;
    }

    .email {
        display: flex;
        align-items: center;
        border: 3px solid #f1d476;
        font-size: $md-font-size;
        padding: 8px;
        white-space: nowrap;
        cursor: pointer;

        img {
            height: 40px;
            width: 40px;
            margin-right: 5px;
        }
    }
}

@media (max-width: 576px) {
    .katana__menu .header__logo {
        height: 26px;   
        margin-right: 0;
    }

    .katana__menu .email {
        padding: 5px;
        font-size: 12px;

        img {
            height: 20px;
            width: 20px;
            margin-right: 3px;
        }
    }
}