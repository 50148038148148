@import '../../styles/variables';

.title_section__area {
    padding-top: 400px;

    .title_section__title {
        text-align: end;
        padding: 30px 30px 30px 0;
        font-size: $title-font-size;
        line-height: .9;

        .description {
            font-size: $xl-font-size;
            font-weight: 300;
        }
    }
}

.section_oil {
    background-color: transparent;
    background-image: url('../../images/bg__section_2.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .section_oil__area {
        .title_oil__images {
            img {
                @media (max-width: 1200px) {
                    height: 400px;
                }
                @media (max-width: 996px) {
                    height: 200px;
                }
            }
        }
        .title_oil__content {
            text-align: start;
            height: 100%;
            display: flex;
            align-items: center;

            .title_oil__name {
                text-align: start;
                font-size: $xl-font-size;
                margin-bottom: 20px;
            }

            .title_oil__description {
                font-size: $lg-font-size;
            }

        }

        .title_oil__logo {
            height: 100%;
            display: flex;
            align-items: end;
            justify-content: end;

            img {
                height: 50px;
            }
        }

    }
}

@media (max-width: 1600px) {
    .title__area {
        .title_section__area {
            padding-top: 300px;

            .title_section__title {
                padding: 20px;
                font-size: calc($title-font-size * 0.7);

                .description {
                    font-size: calc($xl-font-size * 0.8);
                }
            }
        }
    }
}

@media (max-width: 1200px) {
    .title__area {
        .title_section__area {
            padding-top: 250px;

            .title_section__title {
                padding: 20px;
                font-size: calc($title-font-size * 0.6);

                .description {
                    font-size: calc($xl-font-size * 0.7);
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .title_section__area {
        padding-top: 200px;

        .title_section__title {
            padding: 15px;

            .description {
                font-size: calc($xl-font-size * 0.85);
            }
        }
    }
}

@media (max-width: 768px) {
    .title_section__area {
        padding-top: 150px;

        .title_section__title {
            font-size: calc($title-font-size * 0.8);

            .description {
                font-size: calc($xl-font-size * 0.8);
            }
        }
    }
}

@media (max-width: 576px) {
    .title_section__area {
        padding-top: 100px;

        .title_section__title {
            padding: 10px;

            .description {
                font-size: calc($xl-font-size * 0.75);
            }
        }
    }
}