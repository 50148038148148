.container {
    padding: 0 16px;
    max-width: 1600px;
    margin: 0 auto;

}

@media (max-width: 1600px) {
    .container {
        padding: 0 64px;
    }

    @media (max-width: 1200px) {
        .container {
            padding: 0 48px;
        }
    }

    @media (max-width: 992px) {
        .container {
            padding: 0 40px;
        }
    }


    @media (max-width: 768px) {
        .container {
            padding: 0 32px;
        }
    }

    @media (max-width: 576px) {
        .container {
            padding: 0 12px;
        }
    }

}