@import '../../styles/variables';

.section_footer {
    padding: 100px 0;
    background-color: #000;

    img {
        height: 100px;

        @media (max-width: 996px) {
            height: 50px;
        }
    }

    .social__area {
        margin-top: 30px;
        display: flex;
        justify-content: center;

        .social__content {
            display: flex;
            align-items: center;

            .icon {
                margin-right: 10px;
                height: 40px;
                width: 40px;
                background-color: rgba(213, 168, 81, 1);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .social__content:not(:last-child) {
            margin-right: 20px;
        }
    }

    .site__area {
        font-size: $lg-font-size;
        margin-top: 20px;

        span {
            border-bottom: 2px solid #C39813;
        }
    }
}


@media (max-width: 600px) {
    .section_footer .social__area {
        display: block;
    }
}
